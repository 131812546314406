import type { WindowMessages } from "@/lib/helpers/iframes/types";

import { getSessionUuid, getSolvariClientId } from "@/lib/argus/client";

import type { WindowMessageContext } from "./windowMessage";

import { onWindowMessage, postWindowMessage } from "./windowMessage";

function onComponentLoaded(
  component: string,
  callback: (context: WindowMessageContext) => void,
) {
  onWindowMessage("loaded", ({ component: loadedComponent }, context) => {
    if (component === loadedComponent) {
      callback(context);
    }
  });
}

function initOnIframeResize() {
  onWindowMessage("resize", ({ height }, { iframe }) => {
    iframe!.style.height = `${height}px`;
  });
}

function initOnWindowRedirect() {
  onWindowMessage("redirect", ({ url }) => {
    window.location.href = url;
  });
}

function initOnIframeScroll() {
  onWindowMessage("scroll", ({ position }, { iframe }) => {
    const totalOffset =
      iframe!.getBoundingClientRect().top + window.scrollY + position;
    scrollTo(window.scrollX, totalOffset);
  });
}

function postPrefill(
  prefills: WindowMessages["prefill"],
  iframe: HTMLIFrameElement,
) {
  postWindowMessage("prefill", prefills, iframe.contentWindow!);
}

function postArgusSession({ iframe }: WindowMessageContext) {
  const client = getSolvariClientId();
  const session = getSessionUuid();
  if ((client || session) && iframe?.contentWindow) {
    postWindowMessage("argus", { client, session }, iframe.contentWindow);
  }
}

export {
  initOnIframeResize,
  initOnIframeScroll,
  initOnWindowRedirect,
  onComponentLoaded,
  postArgusSession,
  postPrefill,
};
